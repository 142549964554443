@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.staff {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: f.size(30) f.size(15);
  }
  &__wrap {
    @include m.shadow;
    img {
      height: f.size(180);
    }
  }
  &__inner {
    background-color: #fff;
    padding: f.size(8);
    border-radius: f.size(4);
  }
  &__position {
    margin-top: f.size(4);
    font-size: f.size(10);
    &.stylist {
      color: #8db8be;
    }
    &.assistant {
      color: #e3b5ff;
    }
  }
  &__info {
    margin-top: f.size(4);
    display: flex;
    justify-content: space-between;
    a {
      display: block;
      img {
        height: f.size(30);
        width: f.size(30);
      }
    }
  }
  &__name {
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--ja {
      font-size: f.size(14);
      font-weight: bold;
    }
    &--en {
      font-size: f.size(10);
      font-weight: normal;
      color: #bababa;
    }
  }
  &__message {
    height: 0;
    &--ttl {
      margin-top: f.size(8);
      font-size: f.size(12);
      text-decoration: underline;
    }
    &--txt {
      margin-top: f.size(4);
      font-size: f.size(12);
      color: #606060;
      line-height: 1.8;
    }
    &--inner {
      visibility: hidden;
      opacity: 0;
      margin-bottom: f.size(16);
    }
    &.active {
      height: auto;
      margin-top: f.size(16);
      .staff__message--inner {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }
  &__view {
    cursor: pointer;
    font-size: f.size(12);
    font-weight: bold;
    color: #5f651e;
    display: inline-block;
    text-decoration: underline;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .staff {
    &__list {
      grid-template-columns: repeat(4, 1fr);
      gap: f.size(30, 1440) f.size(30, 1440);
    }
    &__wrap {
      img {
        height: f.size(300, 1440);
        object-position: top;
      }
    }
    &__inner {
      padding: f.size(8, 1440);
      border-radius: f.size(4, 1440);
    }
    &__position {
      margin-top: f.size(6, 1440);
      font-size: f.size(12, 1440);
    }
    &__info {
      margin-top: f.size(4, 1440);
      a {
        img {
          height: f.size(30, 1440);
          width: f.size(30, 1440);
        }
      }
    }
    &__name {
      &--ja {
        font-size: f.size(16, 1440);
      }
      &--en {
        font-size: f.size(12, 1440);
      }
    }
    &__message {
      &--ttl {
        margin-top: f.size(10, 1440);
        font-size: f.size(14, 1440);
      }
      &--txt {
        margin-top: f.size(6, 1440);
        font-size: f.size(14, 1440);
      }
      &--inner {
        margin-bottom: f.size(16, 1440);
      }
      &.active {
        margin-top: f.size(20, 1440);
      }
    }
    &__view {
      font-size: f.size(14, 1440);
    }
  }
}
