@use '../foundation/function' as f;
@use '../foundation/mixin' as m;

.l-header {
  height: f.size(60);
  width: 100vw;
  background-color: rgba(#fff, 30%);
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 f.size(10);
  z-index: 1000;
  transition: background-color 0.3s;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  &__logo {
    width: f.size(100);
    // filter: invert(1);
  }
  &__sns {
    display: flex;
    align-items: center;
    gap: f.size(8);
    li {
      height: f.size(28);
      width: f.size(60);
      a {
        font-size: f.size(10);
        font-weight: bold;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
    &--tel {
      background-color: #000;
    }
    &--web {
      background-color: #dea7e0;
    }
    &--line {
      background-color: #06c755;
    }
  }
  &__burger {
    height: f.size(22);
    width: f.size(30);
    position: relative;
    &--item {
      height: 2px;
      width: 100%;
      background-color: #5f651e;
      border-radius: 4px;
      display: inline-block;
      position: absolute;
      transition: transform 0.3s;
      &:nth-of-type(1) {
        top: 0;
        left: 0;
      }
      &:nth-of-type(2) {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &:nth-of-type(3) {
        bottom: 0;
        left: 0;
      }
    }
  }
  &.bgOn {
    background-color: rgba(#fff, 90%);
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .l-header {
    height: f.size(100, 1920);
    padding: 0 f.size(40, 1920);
    &__inner {
      justify-content: start;
      gap: f.size(60, 1920);
    }
    &__logo {
      width: f.size(180, 1920);
      filter: invert(0);
      transition: 0.3s;
    }
    &__menu {
      display: flex;
      align-items: center;
      gap: f.size(30, 1920);
      li {
        a {
          // color: #fff;
          font-size: f.size(18, 1920);
          transition: 0.3s;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    &__sns {
      margin-left: auto;
      gap: f.size(16, 1920);
      li {
        height: f.size(60, 1920);
        width: f.size(150, 1920);
        transition: 0.3s;
        a {
          font-size: f.size(18, 1920);
        }
      }
      &--tel {
        border: 2px solid #000;
        &:hover {
          background-color: transparent;
          a {
            color: #000;
          }
        }
      }
      &--web {
        border: 2px solid #dea7e0;
        &:hover {
          background-color: transparent;
          a {
            color: #dea7e0;
          }
        }
      }
      &--line {
        border: 2px solid #06c755;
        &:hover {
          background-color: transparent;
          a {
            color: #06c755;
          }
        }
      }
    }
    &.bgOn {
      .l-header {
        // &__logo {
        //   filter: invert(1);
        // }
        &__menu {
          li {
            a {
              color: #222;
            }
          }
        }
      }
    }
  }
}
