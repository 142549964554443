@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.c-btn {
  height: f.size(40);
  width: 100%;
  border: 1px solid #434343;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-transform: uppercase;
    font-size: f.size(12);
    color: #434343;
  }
  &.recruit {
    height: f.size(50);
    background-color: #8db8be;
    font-weight: bold;
    border: none;
    a {
      color: #fff;
      font-size: f.size(18);
    }
  }
  &.line {
    height: f.size(50);
    background-color: #06c755;
    font-size: f.size(18);
    border: none;
    a {
      color: #fff;
      font-size: f.size(18);
      font-weight: bold;
      gap: f.size(8);
      img {
        width: f.size(48);
        height: f.size(48);
      }
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .c-btn {
    height: f.size(60, 1920);
    width: 100%;
    transition: 0.3s;
    a {
      font-size: f.size(18, 1920);
      transition: 0.3s;
    }
    &:hover {
      background-color: #434343;
      a {
        color: #fff;
      }
    }
    &.recruit {
      height: f.size(50, 1920);
      a {
        font-size: f.size(18, 1920);
      }
    }
    &.line {
      height: f.size(50, 1920);
      background-color: #06c755;
      font-size: f.size(18, 1920);
      border: none;
      a {
        color: #fff;
        font-size: f.size(18, 1920);
        font-weight: bold;
        gap: f.size(8, 1920);
        img {
          width: f.size(48, 1920);
          height: f.size(48, 1920);
        }
      }
    }
  }
}
