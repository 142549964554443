@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

/* ---------------------------------------- */
/*  base                                    */
/* ---------------------------------------- */
.top {
  padding: f.size(100) f.size(20) f.size(100);
  .section {
    & + .section {
      margin-top: f.size(80);
    }
    &__btn {
      margin-top: f.size(30);
    }
    &__ttl {
      position: relative;
      h2 {
        font-size: f.size(28);
        padding-left: f.size(8);
        border-left: 5px solid #7b5544;
      }
      p {
        font-size: f.size(60);
        font-weight: bold;
        color: #f2f2f2;
        @include m.over;
        position: absolute;
        text-align: right;
        top: 0;
        right: 0;
        z-index: -1;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  .top-concept-swiper {
    overflow-x: hidden;
  }
  .top-style-concept-swiper {
    overflow-x: hidden;
  }
}

/* ---------------------------------------- */
/*  kv                                      */
/* ---------------------------------------- */
.kv {
  width: 100%;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  /* ---------------------------------------- */
  /*  base                                    */
  /* ---------------------------------------- */
  .top {
    max-width: f.size(1440, 1920);
    margin: 0 auto;
    padding: f.size(100, 1440) 0 f.size(100, 1440);
    .section {
      & + .section {
        margin-top: f.size(120, 1440);
      }
      &__btn {
        width: f.size(300, 1920);
        margin-left: auto;
        margin-top: auto;
      }
      &__ttl {
        h2 {
          font-size: f.size(32, 1440);
          padding-left: f.size(10, 1440);
          border-left: f.size(8, 1440) solid #7b5544;
          line-height: 1.2;
        }
        p {
          font-size: f.size(100, 1440);
          width: 100%;
          margin: 0;
          line-height: 1;
          top: 0;
          right: 0;
        }
      }
    }
  }

  /* ---------------------------------------- */
  /*  kv                                      */
  /* ---------------------------------------- */
  .kv {
    width: 100%;
    img {
      height: 100%;
    }
  }
}
