@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.p-news {
  &__list {
    margin-top: f.size(20);
  }
  &__item {
    display: flex;
    align-items: flex-end;
    gap: f.size(16);
    time {
      font-size: f.size(12);
      color: #707070;
    }
    a {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      font-size: f.size(14);
      font-weight: bold;
      text-decoration: underline;
    }
    & + li {
      margin-top: f.size(8);
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .p-news {
    &__list {
      margin-top: f.size(40, 1440);
    }
    &__item {
      gap: f.size(32, 1440);
      time {
        font-size: f.size(14, 1440);
      }
      a {
        font-size: f.size(18, 1440);
      }
      & + li {
        margin-top: f.size(8, 1440);
      }
    }
  }
}
