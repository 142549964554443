@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif:wght@400;700&display=swap");
body {
  background-color: #fafafa;
  color: #222;
  font-family: "Noto Sans JP", sans-serif;
  overflow-x: hidden;
}

html.open .l-nav {
  transform: translate(0, 0);
}
html.open .l-header__burger--item:nth-of-type(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
html.open .l-header__burger--item:nth-of-type(2) {
  transform: rotate3d(1, 0, 0, 90deg);
}
html.open .l-header__burger--item:nth-of-type(3) {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.ff-serif {
  font-family: "Noto Serif", serif;
}

.is-pc {
  display: none;
}

@media only screen and (min-width: 768px) {
  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }
  .pc-flex {
    display: flex;
    gap: 2.0833333333vw;
  }
  .pc-flex-col {
    display: flex;
    flex-direction: column;
  }
  .jc-center {
    justify-content: center;
  }
  .align-c {
    align-items: center;
  }
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.6rem;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: bottom;
}

input,
button,
textarea,
select {
  resize: none;
  font: inherit;
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  color: inherit;
}

li {
  list-style-type: none;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}

button {
  display: inline-block;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition: opacity 0.3s;
}

figure {
  margin-bottom: 0 !important;
}

.l-footer {
  padding: 10.6666666667vw 5.3333333333vw 5.3333333333vw;
  background-color: #8db8be;
  text-align: center;
}
.l-footer__logo {
  margin: 0 auto;
  display: inline-grid;
  justify-content: center;
  gap: 2.6666666667vw;
}
.l-footer__logo img:first-of-type {
  justify-self: center;
  width: 21.3333333333vw;
  height: 21.3333333333vw;
}
.l-footer__logo img:last-of-type {
  width: 40vw;
}
.l-footer__sns {
  margin-top: 2.6666666667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.2666666667vw;
}
.l-footer__sns img:first-of-type {
  width: 8vw;
  height: 8vw;
}
.l-footer__sns img:last-of-type {
  width: 8vw;
  height: 8vw;
}
.l-footer__copy {
  display: block;
  margin-top: 13.3333333333vw;
  color: #fff;
  font-size: 2.6666666667vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .l-footer {
    padding: 3.125vw 1.0416666667vw 1.0416666667vw;
  }
  .l-footer__logo {
    gap: 1.0416666667vw;
    transition: 0.3s;
  }
  .l-footer__logo img:first-of-type {
    width: 10.4166666667vw;
    height: 10.4166666667vw;
  }
  .l-footer__logo img:last-of-type {
    width: 10.4166666667vw;
  }
  .l-footer__logo:hover {
    opacity: 0.7;
  }
  .l-footer__sns {
    margin-top: 1.0416666667vw;
    gap: 1.0416666667vw;
  }
  .l-footer__sns img:first-of-type {
    width: 2.6041666667vw;
    height: 2.6041666667vw;
  }
  .l-footer__sns img:last-of-type {
    width: 2.6041666667vw;
    height: 2.6041666667vw;
  }
  .l-footer__copy {
    margin-top: 2.6041666667vw;
    font-size: 0.7291666667vw;
  }
}
.l-header {
  height: 16vw;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 2.6666666667vw;
  z-index: 1000;
  transition: background-color 0.3s;
}
.l-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.l-header__logo {
  width: 26.6666666667vw;
}
.l-header__sns {
  display: flex;
  align-items: center;
  gap: 2.1333333333vw;
}
.l-header__sns li {
  height: 7.4666666667vw;
  width: 16vw;
}
.l-header__sns li a {
  font-size: 2.6666666667vw;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.l-header__sns--tel {
  background-color: #000;
}
.l-header__sns--web {
  background-color: #dea7e0;
}
.l-header__sns--line {
  background-color: #06c755;
}
.l-header__burger {
  height: 5.8666666667vw;
  width: 8vw;
  position: relative;
}
.l-header__burger--item {
  height: 2px;
  width: 100%;
  background-color: #5f651e;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  transition: transform 0.3s;
}
.l-header__burger--item:nth-of-type(1) {
  top: 0;
  left: 0;
}
.l-header__burger--item:nth-of-type(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.l-header__burger--item:nth-of-type(3) {
  bottom: 0;
  left: 0;
}
.l-header.bgOn {
  background-color: rgba(255, 255, 255, 0.9);
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .l-header {
    height: 5.2083333333vw;
    padding: 0 2.0833333333vw;
  }
  .l-header__inner {
    justify-content: start;
    gap: 3.125vw;
  }
  .l-header__logo {
    width: 9.375vw;
    filter: invert(0);
    transition: 0.3s;
  }
  .l-header__menu {
    display: flex;
    align-items: center;
    gap: 1.5625vw;
  }
  .l-header__menu li a {
    font-size: 0.9375vw;
    transition: 0.3s;
  }
  .l-header__menu li a:hover {
    opacity: 0.7;
  }
  .l-header__sns {
    margin-left: auto;
    gap: 0.8333333333vw;
  }
  .l-header__sns li {
    height: 3.125vw;
    width: 7.8125vw;
    transition: 0.3s;
  }
  .l-header__sns li a {
    font-size: 0.9375vw;
  }
  .l-header__sns--tel {
    border: 2px solid #000;
  }
  .l-header__sns--tel:hover {
    background-color: transparent;
  }
  .l-header__sns--tel:hover a {
    color: #000;
  }
  .l-header__sns--web {
    border: 2px solid #dea7e0;
  }
  .l-header__sns--web:hover {
    background-color: transparent;
  }
  .l-header__sns--web:hover a {
    color: #dea7e0;
  }
  .l-header__sns--line {
    border: 2px solid #06c755;
  }
  .l-header__sns--line:hover {
    background-color: transparent;
  }
  .l-header__sns--line:hover a {
    color: #06c755;
  }
  .l-header.bgOn .l-header__menu li a {
    color: #222;
  }
}
.l-inner {
  padding: 16vw 5.3333333333vw 26.6666666667vw;
}

.l-content-inner {
  margin-top: 13.3333333333vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .pc-inner {
    max-width: 62.5vw;
    margin: 0 auto;
  }
  .l-inner {
    padding: 9.375vw 12.5vw 5.2083333333vw;
  }
  .l-content-inner {
    margin-top: 4.1666666667vw;
  }
}
.l-nav {
  inset: 0;
  transform: translate(100%, 0);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  background-color: #fafafa;
  z-index: 500;
  padding: 21.3333333333vw 5.3333333333vw 5.3333333333vw;
  transition: transform 0.3s;
}
.l-nav__logo {
  display: block;
  height: 24vw;
  width: 24vw;
  margin: 0 auto;
}
.l-nav__list {
  margin-top: 5.3333333333vw;
  display: grid;
  gap: 4.2666666667vw;
  text-align: center;
}
.l-nav__recruit {
  width: 80vw;
  margin: 0 auto;
  margin-top: 4.2666666667vw;
}
.l-nav__recruit p {
  font-size: 4.8vw;
  position: relative;
}
.l-nav__recruit p::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #222;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.l-nav__recruit .c-btn__wrap {
  margin-top: 4.2666666667vw;
}
.l-nav__recruit .c-btn__wrap:last-of-type {
  margin-top: 2.1333333333vw;
}
.l-nav__sns {
  margin-top: 4.2666666667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.2666666667vw;
}
.l-nav__sns li:first-of-type {
  width: 8vw;
  height: 8vw;
}
.l-nav__sns li:last-of-type {
  width: 8vw;
  height: 8vw;
}
.l-nav__sns a {
  display: block;
}
.l-nav__footer {
  margin-top: 13.3333333333vw;
}
.l-nav__footer--list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.l-nav__footer--list li {
  width: 25%;
  text-align: center;
}
.l-nav__footer--list li a {
  font-size: 3.7333333333vw;
  color: #fff;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .l-nav__footer {
    margin-top: 2.6041666667vw;
  }
  .l-nav__footer--list {
    flex-wrap: nowrap;
    gap: 1.5625vw;
  }
  .l-nav__footer--list li {
    width: auto;
  }
  .l-nav__footer--list li a {
    font-size: 0.9375vw;
    color: #fff;
  }
}
.u-mt-l {
  margin-top: 21.3333333333vw;
}

.u-mt-m {
  margin-top: 8vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .u-mt-l {
    margin-top: 5.5555555556vw;
  }
  .u-mt-m {
    margin-top: 2.0833333333vw;
  }
}
.c-btn {
  height: 10.6666666667vw;
  width: 100%;
  border: 1px solid #434343;
}
.c-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  font-size: 3.2vw;
  color: #434343;
}
.c-btn.recruit {
  height: 13.3333333333vw;
  background-color: #8db8be;
  font-weight: bold;
  border: none;
}
.c-btn.recruit a {
  color: #fff;
  font-size: 4.8vw;
}
.c-btn.line {
  height: 13.3333333333vw;
  background-color: #06c755;
  font-size: 4.8vw;
  border: none;
}
.c-btn.line a {
  color: #fff;
  font-size: 4.8vw;
  font-weight: bold;
  gap: 2.1333333333vw;
}
.c-btn.line a img {
  width: 12.8vw;
  height: 12.8vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .c-btn {
    height: 3.125vw;
    width: 100%;
    transition: 0.3s;
  }
  .c-btn a {
    font-size: 0.9375vw;
    transition: 0.3s;
  }
  .c-btn:hover {
    background-color: #434343;
  }
  .c-btn:hover a {
    color: #fff;
  }
  .c-btn.recruit {
    height: 2.6041666667vw;
  }
  .c-btn.recruit a {
    font-size: 0.9375vw;
  }
  .c-btn.line {
    height: 2.6041666667vw;
    background-color: #06c755;
    font-size: 0.9375vw;
    border: none;
  }
  .c-btn.line a {
    color: #fff;
    font-size: 0.9375vw;
    font-weight: bold;
    gap: 0.4166666667vw;
  }
  .c-btn.line a img {
    width: 2.5vw;
    height: 2.5vw;
  }
}
.c-info {
  display: grid;
  gap: 4.2666666667vw;
  font-size: 3.7333333333vw;
}
.c-info__ttl {
  border-bottom: 1px solid #bababa;
}
.c-info__detail {
  padding-left: 1em;
  margin-top: 2.1333333333vw;
  line-height: 1.5;
}
.c-info__map {
  margin-top: 4.2666666667vw;
}
.c-info__map iframe {
  width: 100%;
  height: 80vw;
}
.c-info__btn {
  margin-top: 4.2666666667vw;
}
.c-info__btn a {
  text-transform: none;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .c-info {
    display: grid;
    gap: 0;
    font-size: 0.9722222222vw;
  }
  .c-info__wrap {
    display: grid;
    grid-template-columns: 5.5555555556vw 1fr;
    align-items: center;
    gap: 0 6.9444444444vw;
    padding: 2.0833333333vw 1.3888888889vw;
    border-top: 1px solid #bababa;
  }
  .c-info__wrap:last-of-type {
    border-bottom: 1px solid #bababa;
  }
  .c-info__ttl {
    border-bottom: none;
  }
  .c-info__detail {
    padding-left: 0;
    margin-top: 0;
  }
  .c-info__map {
    grid-column: 1/3;
    margin-top: 2.0833333333vw;
  }
  .c-info__map iframe {
    width: 100%;
    height: 34.7222222222vw;
  }
  .c-info__btn {
    text-align: center;
    grid-column: 1/3;
    margin-top: 2.0833333333vw;
  }
  .c-info__btn .c-btn {
    width: 20.8333333333vw;
  }
}
.c-pager {
  margin-top: clamp(56px, 4.1666666667vw, 96px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(21px, 1.5625vw, 36px);
}
.c-pager .page-numbers {
  height: clamp(35px, 2.6041666667vw, 60px);
  width: clamp(35px, 2.6041666667vw, 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
  border: 1px solid #111;
  border-radius: 50%;
  color: #fff;
  font-size: clamp(11.2px, 0.8333333333vw, 19.2px);
}
.c-pager .page-numbers.current {
  color: #111;
  background-color: #fff;
}
.c-pager .page-numbers.dots {
  width: clamp(4.2px, 0.3125vw, 7.2px);
  background-color: transparent;
  border: none;
  color: #111;
}
.c-pager .next,
.c-pager .prev {
  height: clamp(35px, 2.6041666667vw, 60px);
  width: clamp(35px, 2.6041666667vw, 60px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111;
}
.c-pager .prev span {
  width: clamp(8.4px, 0.625vw, 14.4px);
  height: clamp(8.4px, 0.625vw, 14.4px);
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: translateX(clamp(1.4px, 0.1041666667vw, 2.4px)) rotate(-135deg);
}
.c-pager .next span {
  width: clamp(8.4px, 0.625vw, 14.4px);
  height: clamp(8.4px, 0.625vw, 14.4px);
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: translateX(calc(-1 * clamp(1.4px, 0.1041666667vw, 2.4px))) rotate(45deg);
}

@media only screen and (max-width: 767px) {
  .c-pager {
    margin-top: clamp(35px, 13.3333333333vw, 60px);
    gap: clamp(8.4px, 3.2vw, 14.4px);
  }
  .c-pager .page-numbers {
    height: clamp(24.5px, 9.3333333333vw, 42px);
    width: clamp(24.5px, 9.3333333333vw, 42px);
    font-size: clamp(11.2px, 0.8333333333vw, 19.2px);
  }
  .c-pager .page-numbers.dots {
    width: clamp(4.2px, 1.6vw, 7.2px);
  }
  .c-pager .next,
  .c-pager .prev {
    height: clamp(24.5px, 9.3333333333vw, 42px);
    width: clamp(24.5px, 9.3333333333vw, 42px);
  }
  .c-pager .prev span {
    width: clamp(7px, 2.6666666667vw, 12px);
    height: clamp(7px, 2.6666666667vw, 12px);
    transform: translateX(clamp(1.4px, 0.5333333333vw, 2.4px)) rotate(-135deg);
  }
  .c-pager .next span {
    width: clamp(7px, 2.6666666667vw, 12px);
    height: clamp(7px, 2.6666666667vw, 12px);
    transform: translateX(calc(-1 * clamp(1.4px, 0.5333333333vw, 2.4px))) rotate(45deg);
  }
}
/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
.page-ttl {
  padding-top: 10.6666666667vw;
  position: relative;
}
.page-ttl h1 {
  font-size: 7.4666666667vw;
  font-weight: bold;
  position: relative;
}
.page-ttl h1::before {
  content: "";
  height: 0.8vw;
  width: 50%;
  background-color: #7b5544;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.page-ttl h1::after {
  content: "";
  height: 0.8vw;
  width: 10.6666666667vw;
  background-color: #5f651e;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.page-ttl p {
  font-size: 16vw;
  font-weight: bold;
  color: #f2f2f2;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
}
.page-ttl p::first-letter {
  text-transform: uppercase;
}

.page-subTtl {
  font-size: 6.4vw;
  font-weight: bold;
  position: relative;
  display: inline-block;
  line-height: 1;
  color: #222;
}
.page-subTtl::after {
  content: "";
  height: 0.2666666667vw;
  width: 100%;
  background-color: #222;
  position: absolute;
  bottom: -0.5333333333vw;
  left: 0;
}
.page-subTtl.over {
  white-space: nowrap;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .page-ttl {
    padding-top: 2.7777777778vw;
    position: relative;
  }
  .page-ttl h1 {
    font-size: 1.9444444444vw;
    font-weight: bold;
    position: relative;
  }
  .page-ttl h1::before {
    content: "";
    height: 0.2083333333vw;
    width: 50%;
    background-color: #7b5544;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .page-ttl h1::after {
    content: "";
    height: 0.2083333333vw;
    width: 2.7777777778vw;
    background-color: #5f651e;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  .page-ttl p {
    font-size: 4.1666666667vw;
    font-weight: bold;
    color: #f2f2f2;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
  }
  .page-ttl p::first-letter {
    text-transform: uppercase;
  }
  .page-subTtl {
    font-size: 1.6666666667vw;
    font-weight: bold;
    position: relative;
    display: inline-block;
    line-height: 1;
    color: #222;
  }
  .page-subTtl::after {
    content: "";
    height: 0.0694444444vw;
    width: 100%;
    background-color: #222;
    position: absolute;
    bottom: -0.1388888889vw;
    left: 0;
  }
  .page-subTtl.over {
    white-space: nowrap;
  }
}
.c-instagram {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
.p-concept__wrap {
  margin-top: 10.6666666667vw;
  text-align: center;
}
.p-concept__wrap img {
  width: 32vw;
}
.p-concept__philosophy {
  font-size: 4.2666666667vw;
  font-weight: bold;
  margin-top: 8vw;
}
.p-concept__txt {
  margin-top: 4.2666666667vw;
  font-size: 3.2vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .p-concept__wrap {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    gap: 0 2.7777777778vw;
    margin-top: 3.4722222222vw;
    margin-left: 0;
    text-align: left;
  }
  .p-concept__wrap img {
    height: 15.625vw;
    width: auto;
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .p-concept__wrap .pc-flex-col {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .p-concept__wrap .section__btn {
    grid-row: 2/3;
    grid-column: 1/3;
    margin-top: 0 !important;
  }
  .p-concept__wrap .p-concept__insta {
    grid-row: 1/3;
    grid-column: 3/4;
  }
  .p-concept__philosophy {
    font-size: 1.3888888889vw;
    margin-top: 0.5208333333vw;
  }
  .p-concept__txt {
    font-size: 1.1111111111vw;
    margin-top: 0.9375vw;
  }
}
.p-menu__wrap {
  margin-top: 8vw;
}
.p-menu__list {
  display: grid;
  gap: 2.1333333333vw;
}
.p-menu__list li {
  display: flex;
  justify-content: space-between;
}
.p-menu__name {
  font-size: 4.2666666667vw;
}
.p-menu__price {
  font-size: 4.2666666667vw;
  margin-left: clamp(14px, 1.0416666667vw, 24px);
}
.p-menu__note {
  margin-top: 2.1333333333vw;
  font-size: 2.6666666667vw;
  color: #606060;
  text-align: right;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .p-menu__wrap {
    max-width: 41.6666666667vw;
    margin: 0 auto;
    margin-top: 2.7777777778vw;
  }
  .p-menu__list {
    gap: 1.1111111111vw;
  }
  .p-menu__name {
    font-size: 1.25vw;
  }
  .p-menu__price {
    font-size: 1.25vw;
    margin-left: clamp(21px, 2.0833333333vw, 36px);
  }
  .p-menu__note {
    margin-top: 0.5555555556vw;
    font-size: 0.9722222222vw;
  }
  .p-menu div.section__btn {
    margin: 0 auto;
    margin-top: 2.7777777778vw;
  }
}
.p-news__list {
  margin-top: 5.3333333333vw;
}
.p-news__item {
  display: flex;
  align-items: flex-end;
  gap: 4.2666666667vw;
}
.p-news__item time {
  font-size: 3.2vw;
  color: #707070;
}
.p-news__item a {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 3.7333333333vw;
  font-weight: bold;
  text-decoration: underline;
}
.p-news__item + li {
  margin-top: 2.1333333333vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .p-news__list {
    margin-top: 2.7777777778vw;
  }
  .p-news__item {
    gap: 2.2222222222vw;
  }
  .p-news__item time {
    font-size: 0.9722222222vw;
  }
  .p-news__item a {
    font-size: 1.25vw;
  }
  .p-news__item + li {
    margin-top: 0.5555555556vw;
  }
}
.p-recruit__wrap {
  margin-top: 8vw;
}
.p-recruit__wrap img {
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  height: 33.6vw;
  object-position: top;
}
.p-recruit__txt {
  margin-top: 5.3333333333vw;
  text-align: center;
  font-size: 4.2666666667vw;
}
.p-recruit__important {
  margin-top: 5.3333333333vw;
  text-align: center;
}
.p-recruit__important--list {
  padding: 0 2.6666666667vw;
  margin-top: 4.2666666667vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2.6666666667vw;
}
.p-recruit__important--list li {
  height: 18.6666666667vw;
  border-radius: 1.0666666667vw;
  background-color: #fff;
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.16));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.7333333333vw;
  position: relative;
}
.p-recruit__important--list li::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-recruit__important--list li:nth-of-type(1)::after {
  height: 13.3333333333vw;
  width: 13.3333333333vw;
  background: url("../img/balance.png") no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.4;
}
.p-recruit__important--list li:nth-of-type(2)::after {
  height: 21.3333333333vw;
  width: 21.3333333333vw;
  background: url("../img/human.png") no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.4;
}
.p-recruit__important--list li:nth-of-type(3)::after {
  height: 13.3333333333vw;
  width: 17.6vw;
  background: url("../img/time.png") no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.2;
}
.p-recruit__important--list li:nth-of-type(4)::after {
  height: 14.9333333333vw;
  width: 21.3333333333vw;
  background: url("../img/up.png") no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.2;
}
.p-recruit__btn {
  margin-top: 5.3333333333vw;
}
.p-recruit__btn button {
  border-radius: 1.0666666667vw;
}
.p-recruit__note {
  margin-top: 2.1333333333vw;
  font-size: 3.7333333333vw;
}
.p-recruit__note a {
  text-decoration: underline;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .p-recruit__wrap {
    margin-top: 3.4722222222vw;
  }
  .p-recruit__wrap img {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    height: 20.8333333333vw;
    object-position: 0 -6.9444444444vw;
  }
  .p-recruit__txt {
    margin-top: 2.7777777778vw;
    font-size: 1.3888888889vw;
  }
  .p-recruit__important {
    margin-top: 1.3888888889vw;
    font-size: 1.5277777778vw;
  }
  .p-recruit__important--list {
    max-width: 41.6666666667vw;
    margin: 0 auto;
    padding: 0 1.3888888889vw;
    margin-top: 1.3888888889vw;
    gap: 0.6944444444vw;
  }
  .p-recruit__important--list li {
    height: 5.5555555556vw;
    border-radius: 0.2777777778vw;
    font-size: 1.1111111111vw;
  }
  .p-recruit__important--list li:nth-of-type(1)::after {
    height: 3.4722222222vw;
    width: 3.4722222222vw;
  }
  .p-recruit__important--list li:nth-of-type(2)::after {
    height: 5.5555555556vw;
    width: 5.5555555556vw;
  }
  .p-recruit__important--list li:nth-of-type(3)::after {
    height: 3.4722222222vw;
    width: 4.5833333333vw;
  }
  .p-recruit__important--list li:nth-of-type(4)::after {
    height: 3.8888888889vw;
    width: 5.5555555556vw;
  }
  .p-recruit div.section__btn {
    margin: 0 auto;
    margin-top: 2.7777777778vw;
  }
  .p-recruit div.p-recruit__btn {
    max-width: 26.0416666667vw;
    margin: 0 auto;
    margin-top: 2.7777777778vw;
  }
  .p-recruit div.p-recruit__btn .c-btn.line {
    height: 4.1666666667vw;
    border-radius: 0.4166666667vw;
  }
  .p-recruit div.p-recruit__btn .c-btn.line a {
    font-size: 1.1458333333vw;
    transition: 0.3s;
  }
  .p-recruit div.p-recruit__btn .c-btn.line a img {
    width: 2.8645833333vw;
    height: 2.8645833333vw;
  }
  .p-recruit div.p-recruit__btn .c-btn.line a:hover {
    opacity: 0.7;
  }
  .p-recruit__note {
    margin-top: 0.5555555556vw;
    font-size: 0.9722222222vw;
  }
}
.p-staff__wrap {
  margin-top: 8vw;
}
.p-staff__wrap img {
  width: 100%;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .p-staff__wrap {
    margin-top: 3.4722222222vw;
  }
  .p-staff__wrap img {
    width: 100%;
    height: auto;
  }
  .p-staff div.section__btn {
    margin: 0 auto;
    margin-top: 2.7777777778vw;
  }
}
.p-style__wrap {
  padding-bottom: 8vw;
  margin-top: 8vw;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  position: relative;
}
.p-style__img {
  padding: 2.6666666667vw;
  background-color: #fff;
}
.p-style__img img {
  height: 101.3333333333vw;
}
.p-style .swiper-pagination {
  bottom: 0;
}
.p-style .swiper-pagination .swiper-pagination-bullet {
  background: #ffffff;
  border: 1px solid #434343;
  width: 2.6666666667vw;
  height: 2.6666666667vw;
}
.p-style .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #434343;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .p-style__wrap {
    padding-bottom: 2.7777777778vw;
    margin-top: 4.1666666667vw;
  }
  .p-style__img {
    padding: 0.6944444444vw;
  }
  .p-style__img img {
    height: 26.3888888889vw;
  }
  .p-style .swiper-pagination .swiper-pagination-bullet {
    width: 0.6944444444vw;
    height: 0.6944444444vw;
  }
  .p-style div.section__btn {
    margin: 0 auto;
    margin-top: 2.7777777778vw;
  }
}
.concept .page-subTtl {
  left: 50%;
  transform: translateX(-50%);
}
.concept__top {
  color: #434343;
  position: relative;
}
.concept__top--philosophy {
  margin-top: 5.3333333333vw;
  font-size: 4.8vw;
  line-height: 2;
  text-align: center;
  font-weight: bold;
}
.concept__top--value {
  margin-top: 5.3333333333vw;
  font-size: 3.7333333333vw;
  padding-left: 5.3333333333vw;
  list-style: decimal;
}
.concept__top--value li {
  padding-left: 1.0666666667vw;
  list-style: inherit;
}
.concept__top::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 53.3333333333vw;
  height: 53.3333333333vw;
  background: url("../img/logo-circle.png") no-repeat;
  background-size: cover;
  opacity: 0.1;
}
.concept__salon--img {
  position: relative;
  margin-top: 5.3333333333vw;
  padding-bottom: 8vw;
  width: 100vw;
  margin: 0 calc(50% - 50vw);
}
.concept__salon--img .swiper-slide {
  text-align: center;
}
.concept__salon--img .swiper-slide img {
  height: 48vw;
  width: 89.3333333333vw;
}
.concept__salon--img .top-concept-swiper-pagination,
.concept__salon--img .top-style-swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.concept__salon--img .top-concept-swiper-pagination,
.concept__salon--img .top-style-swiper-pagination,
.concept__salon--img .swiper-pagination {
  bottom: 0;
}
.concept__salon--img .top-concept-swiper-pagination .swiper-pagination-bullet,
.concept__salon--img .top-style-swiper-pagination .swiper-pagination-bullet,
.concept__salon--img .swiper-pagination .swiper-pagination-bullet {
  background: #ffffff;
  border: 1px solid #434343;
  width: 2.6666666667vw;
  height: 2.6666666667vw;
}
.concept__salon--img .top-concept-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.concept__salon--img .top-style-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.concept__salon--img .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #434343;
}
.concept__point {
  display: grid;
  gap: 4.2666666667vw;
}
.concept__point--wrap {
  background-color: #fff;
  border-radius: 1.0666666667vw;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  padding: 4.2666666667vw;
}
.concept__point--wrap:first-of-type {
  margin-top: -2.1333333333vw;
}
.concept__point h3 {
  font-size: 4.8vw;
  font-weight: bold;
}
.concept__point h4 {
  font-size: 3.7333333333vw;
}
.concept__point h4 span {
  font-size: 4.8vw;
  margin-right: 1.0666666667vw;
}
.concept__point p {
  font-size: 3.2vw;
  color: #434343;
}
.concept__point.obsession h3,
.concept__point.obsession h4 {
  color: #eaa587;
}
.concept__point.safe h3,
.concept__point.safe h4 {
  color: #8db8be;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .concept .page-subTtl {
    left: 50%;
    transform: translateX(-50%);
  }
  .concept__top {
    color: #434343;
    position: relative;
  }
  .concept__top--philosophy {
    margin-top: 2.0833333333vw;
    font-size: 2.0833333333vw;
    line-height: 2;
    text-align: center;
    font-weight: bold;
  }
  .concept__top--value {
    margin: 0 auto;
    margin-top: 2.0833333333vw;
    font-size: 1.5277777778vw;
    width: 1000px;
    padding-left: 0;
  }
  .concept__top--value li {
    padding-left: 0.5555555556vw;
  }
  .concept__top::after {
    width: 27.7777777778vw;
    height: 27.7777777778vw;
  }
  .concept__salon--img {
    margin-top: 2.7777777778vw;
    padding-bottom: 2.7777777778vw;
  }
  .concept__salon--img .swiper-slide img {
    height: 12.5vw;
    width: 23.2638888889vw;
  }
  .concept__salon--img .top-concept-swiper-pagination .swiper-pagination-bullet,
  .concept__salon--img .top-style-swiper-pagination .swiper-pagination-bullet,
  .concept__salon--img .swiper-pagination .swiper-pagination-bullet {
    width: 0.6944444444vw;
    height: 0.6944444444vw;
  }
  .concept__point {
    gap: 1.25vw;
  }
  .concept__point--wrap {
    border-radius: 0.2777777778vw;
    padding: 1.3888888889vw;
  }
  .concept__point--wrap:first-of-type {
    margin-top: -0.8333333333vw;
  }
  .concept__point h3 {
    font-size: 1.3888888889vw;
  }
  .concept__point h4 {
    font-size: 1.1111111111vw;
  }
  .concept__point h4 span {
    font-size: 1.3888888889vw;
    margin-right: 0.4166666667vw;
  }
  .concept__point p {
    font-size: 0.9722222222vw;
  }
}
.menu__message {
  font-size: 5.3333333333vw;
  font-weight: bold;
  text-align: center;
}
.menu__nav {
  margin-top: 13.3333333333vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 3.2vw;
}
.menu__nav--item a {
  padding: 0.5333333333vw 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.2666666667vw;
  font-weight: bold;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  color: #434343;
}
.menu__nav--item a::after {
  content: attr(data-ja);
  font-size: 2.6666666667vw;
  line-height: 1;
}
.menu__nav--item:nth-of-type(2), .menu__nav--item:nth-of-type(5), .menu__nav--item:nth-of-type(8) {
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
}
.menu__name {
  font-size: 5.8666666667vw;
  font-weight: bold;
  color: #606060;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.menu__name span {
  font-size: 3.2vw;
  margin-left: 2.1333333333vw;
  font-weight: normal;
}
.menu__txt {
  margin-top: 4.2666666667vw;
  font-size: 3.7333333333vw;
  line-height: 1.5;
  color: #434343;
}
.menu__list {
  margin-top: 4.2666666667vw;
  display: grid;
  gap: 4.2666666667vw;
}
.menu__list li {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.1333333333vw;
  border-bottom: 0.2666666667vw solid #606060;
  gap: 5.3333333333vw;
}
.menu__detail {
  font-size: 4.2666666667vw;
  color: #606060;
  display: grid;
}
.menu__detail span {
  font-size: 3.2vw;
  line-height: 1;
}
.menu__price {
  font-size: 4.2666666667vw;
  font-weight: bold;
  color: #606060;
}
.menu__note {
  font-size: 3.2vw;
  color: #606060;
  text-align: right;
}
.menu .coupon__btn {
  width: 100%;
  margin-top: clamp(14px, 1.0416666667vw, 24px);
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .menu__message {
    font-size: 1.3888888889vw;
  }
  .menu__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3.4722222222vw;
    row-gap: 1.1111111111vw;
  }
  .menu__nav--item {
    width: 17.3611111111vw;
  }
  .menu__nav--item a {
    padding: 0.1388888889vw 0;
    font-size: 1.25vw;
    gap: 0.2777777778vw;
  }
  .menu__nav--item a::after {
    font-size: 0.8333333333vw;
  }
  .menu__nav--item:last-of-type {
    grid-column: unset;
  }
  .menu__nav--item:nth-of-type(2), .menu__nav--item:nth-of-type(5), .menu__nav--item:nth-of-type(7) {
    border: none;
  }
  .menu__nav--item:nth-of-type(2), .menu__nav--item:nth-of-type(3), .menu__nav--item:nth-of-type(4), .menu__nav--item:nth-of-type(6), .menu__nav--item:nth-of-type(7), .menu__nav--item:nth-of-type(8) {
    border-left: 1px solid #707070;
  }
  .menu__wrap {
    width: 55.5555555556vw;
    margin: 0 auto;
    margin-top: 4.1666666667vw;
  }
  .menu__name {
    font-size: 1.6666666667vw;
  }
  .menu__name span {
    font-size: 0.9722222222vw;
    margin-left: 0.5555555556vw;
  }
  .menu__txt {
    margin-top: 1.1111111111vw;
    font-size: 1.1111111111vw;
    line-height: 2;
  }
  .menu__list {
    margin-top: 1.3888888889vw;
    display: grid;
    gap: 1.1111111111vw;
  }
  .menu__list li {
    gap: 2.0833333333vw;
    padding-bottom: 0.5555555556vw;
    border-bottom: 0.0694444444vw solid #606060;
  }
  .menu__detail {
    font-size: 1.1111111111vw;
  }
  .menu__detail span {
    font-size: 0.9722222222vw;
  }
  .menu__price {
    font-size: 1.1111111111vw;
  }
  .menu__note {
    font-size: 0.8333333333vw;
  }
  .menu .coupon__btn {
    max-width: 62.5vw;
    margin: 0 auto;
    margin-top: clamp(28px, 2.7777777778vw, 48px);
  }
}
.news__wrap time {
  font-size: 2.6666666667vw;
  color: #bababa;
}
.news__wrap:nth-of-type(n + 2) {
  margin-top: 8vw;
  padding-top: 8vw;
  border-top: 1px solid #c3c3c3;
}
.news__ttl {
  font-size: 4.2666666667vw;
  color: #434343;
  font-weight: bold;
}
.news__content {
  background-color: #fff;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  height: 0;
  visibility: hidden;
  opacity: 0;
}
.news__content p {
  font-size: 3.2vw;
  line-height: 1.75;
  color: #606060;
}
.news__content p.news__view {
  color: #5f651e;
  position: absolute;
  bottom: 4.2666666667vw;
  left: 50%;
  transform: translateX(-50%);
}
.news__content p + figure {
  margin: 4.2666666667vw auto;
}
.news__content figure + p {
  margin-top: 4.2666666667vw;
}
.news__content img {
  display: block;
  margin: 0 auto;
  width: 80%;
  aspect-ratio: 3/2;
}
.news__content.active {
  margin-top: 2.1333333333vw;
  padding: 5.3333333333vw;
  padding-bottom: 21.3333333333vw;
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}
.news__view {
  display: inline-block;
  margin-top: 2.1333333333vw;
  color: #5f651e;
  font-weight: bold;
  font-size: 3.2vw;
  text-decoration: underline;
  text-decoration-color: inherit;
  cursor: pointer;
}
.news__view::first-letter {
  text-transform: uppercase;
}
.news__view.active {
  display: none;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .news__wrap time {
    font-size: 0.8333333333vw;
  }
  .news__wrap:nth-of-type(n + 2) {
    margin-top: 2.0833333333vw;
    padding-top: 2.0833333333vw;
  }
  .news__ttl {
    font-size: 1.3888888889vw;
  }
  .news__content p {
    font-size: 1.1111111111vw;
  }
  .news__content p.news__view {
    bottom: 1.1111111111vw;
  }
  .news__content p + figure {
    margin: 1.3888888889vw auto;
  }
  .news__content figure + p {
    margin-top: 1.3888888889vw;
  }
  .news__content img {
    width: 50%;
  }
  .news__content.active {
    margin-top: 2.2222222222vw;
    padding: 2.7777777778vw 8.3333333333vw;
    padding-bottom: 5.5555555556vw;
  }
  .news__view {
    margin-top: 0.6944444444vw;
    font-size: 0.9722222222vw;
  }
}
.privacy__wrap h2 {
  font-size: 4.8vw;
  font-weight: bold;
  color: #434343;
}
.privacy__wrap h3 {
  font-size: 4.2666666667vw;
  font-weight: bold;
  color: #434343;
}
.privacy__wrap p {
  font-size: 3.7333333333vw;
  color: #606060;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .privacy__wrap h2 {
    font-size: 1.25vw;
  }
  .privacy__wrap h3 {
    font-size: 1.1111111111vw;
  }
  .privacy__wrap p {
    font-size: 0.9722222222vw;
  }
}
.recruit__top {
  color: #434343;
  text-align: center;
}
.recruit__top h2 {
  font-size: 5.3333333333vw;
  font-weight: bold;
}
.recruit__top p {
  margin-top: 5.3333333333vw;
  font-size: 3.7333333333vw;
}
.recruit__nav {
  margin-top: 13.3333333333vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.1333333333vw 4.2666666667vw;
}
.recruit__nav li {
  height: 10.6666666667vw;
  border: 1px solid #707070;
}
.recruit__nav li a {
  font-size: 3.7333333333vw;
  color: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.recruit__btn {
  margin-top: 4.2666666667vw;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
}
.recruit__greeting--wrap {
  margin-top: 6.4vw;
}
.recruit__greeting--wrap img {
  width: 100%;
  height: 93.3333333333vw;
  object-position: top;
}
.recruit__greeting--txt {
  margin-top: 2.1333333333vw;
}
.recruit__greeting--name {
  font-size: 4.2666666667vw;
  font-weight: bold;
}
.recruit__greeting--message {
  margin-top: 2.1333333333vw;
  font-size: 4.2666666667vw;
  color: #434343;
}
.recruit__important--list {
  margin-top: 6.4vw;
  display: grid;
  gap: 5.3333333333vw;
}
.recruit__important--item h3 {
  font-size: 4.2666666667vw;
  font-weight: bold;
}
.recruit__important--item div {
  margin-top: 1.0666666667vw;
  padding: 2.1333333333vw 4.2666666667vw;
  border-radius: 1.0666666667vw;
  position: relative;
  min-height: 45.3333333333vw;
}
.recruit__important--item div ul {
  position: relative;
  z-index: 10;
}
.recruit__important--item div ul li {
  padding-left: 3.2vw;
  font-size: 3.2vw;
  color: #606060;
  position: relative;
}
.recruit__important--item div ul li::before {
  content: "";
  height: 0.8vw;
  width: 0.8vw;
  border-radius: 50%;
  background-color: #606060;
  position: absolute;
  top: 2.6666666667vw;
  left: 0;
}
.recruit__important--item div ul li:nth-of-type(n + 2) {
  margin-top: 1.0666666667vw;
}
.recruit__important--item div::after {
  content: "";
  position: absolute;
  bottom: 5.3333333333vw;
  right: 5.3333333333vw;
}
.recruit__important--item div.balance {
  background-color: #fbebff;
}
.recruit__important--item div.balance::after {
  background: url("../img/balance.png") no-repeat;
  height: 26.6666666667vw;
  width: 26.6666666667vw;
  background-size: cover;
  opacity: 0.3;
  z-index: 5;
}
.recruit__important--item div.human {
  background-color: #ffe9d3;
}
.recruit__important--item div.human::after {
  background: url("../img/human.png") no-repeat;
  height: 32vw;
  width: 32vw;
  background-size: cover;
  opacity: 0.3;
  z-index: 5;
  bottom: 0;
  right: 4vw;
}
.recruit__important--item div.time {
  background-color: #e6ffef;
}
.recruit__important--item div.time::after {
  background: url("../img/time.png") no-repeat;
  height: 26.6666666667vw;
  width: 35.2vw;
  background-size: cover;
  opacity: 0.2;
  z-index: 5;
  right: 0;
}
.recruit__important--item div.growth {
  background-color: #d3fdff;
}
.recruit__important--item div.growth::after {
  background: url("../img/up.png") no-repeat;
  height: 26.6666666667vw;
  width: 37.3333333333vw;
  background-size: cover;
  opacity: 0.2;
  z-index: 5;
}
.recruit__demand--list {
  margin-top: 6.4vw;
}
.recruit__demand--list li {
  padding-left: 3.2vw;
  font-size: 4.2666666667vw;
  color: #434343;
  position: relative;
}
.recruit__demand--list li::before {
  content: "";
  height: 0.8vw;
  width: 0.8vw;
  border-radius: 50%;
  background-color: #434343;
  position: absolute;
  top: 3.7333333333vw;
  left: 0;
}
.recruit__recruitment--content {
  margin-top: 6.4vw;
}
.recruit__recruitment--wrap:nth-of-type(n + 2) {
  margin-top: 4.2666666667vw;
}
.recruit__recruitment--ttl {
  font-size: 4.2666666667vw;
  font-weight: bold;
  border-bottom: 1px solid #bababa;
}
.recruit__recruitment--detail p {
  font-size: 3.7333333333vw;
  color: #606060;
  padding-left: 3.7333333333vw;
}
.recruit__recruitment--detail p.mark {
  position: relative;
  color: #434343;
}
.recruit__recruitment--detail p.mark::before {
  content: "";
  height: 2.6666666667vw;
  width: 2.6666666667vw;
  border-radius: 50%;
  background-color: #8db8be;
  position: absolute;
  top: 2.1333333333vw;
  left: 0;
}
.recruit__recruitment .p-recruit__btn {
  margin-top: clamp(21px, 1.5625vw, 36px);
}
.recruit__line--message {
  margin-top: 6.4vw;
  font-size: 4.2666666667vw;
}
.recruit__line--apply {
  margin-top: 4.2666666667vw;
}
.recruit__line--apply ul li {
  padding-left: 3.2vw;
  font-size: 4.2666666667vw;
  color: #434343;
  position: relative;
}
.recruit__line--apply ul li::before {
  content: "";
  height: 0.8vw;
  width: 0.8vw;
  border-radius: 50%;
  background-color: #434343;
  position: absolute;
  top: 3.7333333333vw;
  left: 0;
}
.recruit__line--link {
  margin-top: 4.2666666667vw;
  font-weight: bold;
  font-size: 4.2666666667vw;
  white-space: nowrap;
}
.recruit__line--link a {
  color: #6cc1ff;
  text-decoration: underline;
}
.recruit__line .c-btn {
  height: 14.6666666667vw;
  border-radius: 1.0666666667vw;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .recruit__top h2 {
    font-size: 1.6666666667vw;
  }
  .recruit__top p {
    margin-top: 1.3888888889vw;
    font-size: 1.25vw;
  }
  .recruit__nav {
    margin-top: 3.4722222222vw;
    gap: 1.1111111111vw 1.1111111111vw;
  }
  .recruit__nav li {
    height: 2.7777777778vw;
  }
  .recruit__nav li a {
    font-size: 1.1111111111vw;
  }
  .recruit__btn {
    margin-top: 1.1111111111vw;
  }
  .recruit__btn .c-btn {
    height: 4.1666666667vw;
  }
  .recruit__btn .c-btn a {
    font-size: 1.1111111111vw;
  }
  .recruit__greeting {
    margin: 0 auto;
    margin-top: 6.9444444444vw;
    width: 62.5vw;
  }
  .recruit__greeting--wrap {
    display: flex;
    justify-content: center;
    gap: 3.4722222222vw;
    margin-top: 2.0833333333vw;
  }
  .recruit__greeting--wrap img {
    width: 30%;
    height: 24.3055555556vw;
  }
  .recruit__greeting--wrap:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  .recruit__greeting--wrap:nth-of-type(n + 2) {
    margin-top: 4.1666666667vw;
  }
  .recruit__greeting--txt {
    margin-top: 0;
  }
  .recruit__greeting--name {
    font-size: 1.3888888889vw;
  }
  .recruit__greeting--message {
    margin-top: 0.8333333333vw;
    font-size: 1.1111111111vw;
  }
  .recruit__important--list {
    margin-top: 2.0833333333vw;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.0833333333vw;
  }
  .recruit__important--item h3 {
    font-size: 1.3888888889vw;
  }
  .recruit__important--item div {
    margin-top: 0.5555555556vw;
    padding: 1.3888888889vw;
    border-radius: 0.2777777778vw;
    min-height: 13.8888888889vw;
  }
  .recruit__important--item div ul li {
    padding-left: 1.1111111111vw;
    font-size: 0.9722222222vw;
  }
  .recruit__important--item div ul li::before {
    height: 0.2777777778vw;
    width: 0.2777777778vw;
    top: 0.8em;
  }
  .recruit__important--item div ul li:nth-of-type(n + 2) {
    margin-top: 0.5555555556vw;
  }
  .recruit__important--item div::after {
    bottom: 1.3888888889vw;
    right: 1.3888888889vw;
  }
  .recruit__important--item div.balance::after {
    height: 6.9444444444vw;
    width: 6.9444444444vw;
  }
  .recruit__important--item div.human::after {
    height: 8.3333333333vw;
    width: 8.3333333333vw;
    right: 1.0416666667vw;
  }
  .recruit__important--item div.time::after {
    height: 6.9444444444vw;
    width: 9.1666666667vw;
  }
  .recruit__important--item div.growth::after {
    height: 6.9444444444vw;
    width: 9.7222222222vw;
  }
  .recruit__demand--list {
    margin-top: 1.6666666667vw;
  }
  .recruit__demand--list li {
    padding-left: 0.9722222222vw;
    font-size: 1.1111111111vw;
  }
  .recruit__demand--list li::before {
    height: 0.2777777778vw;
    width: 0.2777777778vw;
    top: 0.9722222222vw;
  }
  .recruit__recruitment--content {
    width: 55.5555555556vw;
    margin: 0 auto;
    margin-top: 2.0833333333vw;
  }
  .recruit__recruitment--wrap {
    display: flex;
    align-items: center;
    padding: 1.3888888889vw;
    border-top: 1px solid #bababa;
  }
  .recruit__recruitment--wrap:nth-of-type(n + 2) {
    margin-top: 0;
  }
  .recruit__recruitment--wrap:last-of-type {
    border-bottom: 1px solid #bababa;
  }
  .recruit__recruitment--ttl {
    font-size: 1.1111111111vw;
    font-weight: bold;
    border-bottom: none;
    min-width: 6.9444444444vw;
    align-self: flex-start;
  }
  .recruit__recruitment--detail p {
    font-size: 0.9722222222vw;
    color: #606060;
    padding-left: 0.9722222222vw;
  }
  .recruit__recruitment--detail p.mark {
    position: relative;
    color: #434343;
  }
  .recruit__recruitment--detail p.mark::before {
    content: "";
    height: 0.6944444444vw;
    width: 0.6944444444vw;
    border-radius: 50%;
    background-color: #8db8be;
    position: absolute;
    top: 0.5555555556vw;
    left: 0;
  }
  .recruit__recruitment .p-recruit__btn {
    width: 55.5555555556vw;
    margin: 0 auto;
    margin-top: 2.0833333333vw;
  }
  .recruit__recruitment .p-recruit__btn .c-btn {
    height: 4.1666666667vw;
    border-radius: 0.2777777778vw;
  }
  .recruit__recruitment .p-recruit__btn .c-btn a {
    font-size: 1.25vw;
  }
  .recruit__line--message {
    margin-top: 1.6666666667vw;
    font-size: 1.1111111111vw;
  }
  .recruit__line--apply {
    width: 55.5555555556vw;
    margin: 0 auto;
    margin-top: 1.1111111111vw;
  }
  .recruit__line--apply p {
    font-size: 1.1111111111vw;
  }
  .recruit__line--apply ul li {
    padding-left: 0.8333333333vw;
    font-size: 1.25vw;
  }
  .recruit__line--apply ul li::before {
    height: 0.2777777778vw;
    width: 0.2777777778vw;
    top: 0.9722222222vw;
  }
  .recruit__line--link {
    width: 55.5555555556vw;
    margin: 0 auto;
    margin-top: 1.3888888889vw;
    font-size: 1.1111111111vw;
  }
  .recruit__line .c-btn {
    height: 4.1666666667vw;
    border-radius: 0.2777777778vw;
  }
  .recruit__line .c-btn a {
    font-size: 1.25vw;
  }
  .recruit__btn {
    width: 55.5555555556vw;
    margin: 0 auto;
    margin-top: 2.0833333333vw;
  }
}
.staff__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8vw 4vw;
}
.staff__wrap {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
}
.staff__wrap img {
  height: 48vw;
}
.staff__inner {
  background-color: #fff;
  padding: 2.1333333333vw;
  border-radius: 1.0666666667vw;
}
.staff__position {
  margin-top: 1.0666666667vw;
  font-size: 2.6666666667vw;
}
.staff__position.stylist {
  color: #8db8be;
}
.staff__position.assistant {
  color: #e3b5ff;
}
.staff__info {
  margin-top: 1.0666666667vw;
  display: flex;
  justify-content: space-between;
}
.staff__info a {
  display: block;
}
.staff__info a img {
  height: 8vw;
  width: 8vw;
}
.staff__name {
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.staff__name--ja {
  font-size: 3.7333333333vw;
  font-weight: bold;
}
.staff__name--en {
  font-size: 2.6666666667vw;
  font-weight: normal;
  color: #bababa;
}
.staff__message {
  height: 0;
}
.staff__message--ttl {
  margin-top: 2.1333333333vw;
  font-size: 3.2vw;
  text-decoration: underline;
}
.staff__message--txt {
  margin-top: 1.0666666667vw;
  font-size: 3.2vw;
  color: #606060;
  line-height: 1.8;
}
.staff__message--inner {
  visibility: hidden;
  opacity: 0;
  margin-bottom: 4.2666666667vw;
}
.staff__message.active {
  height: auto;
  margin-top: 4.2666666667vw;
}
.staff__message.active .staff__message--inner {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}
.staff__view {
  cursor: pointer;
  font-size: 3.2vw;
  font-weight: bold;
  color: #5f651e;
  display: inline-block;
  text-decoration: underline;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.staff__view::first-letter {
  text-transform: uppercase;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .staff__list {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.0833333333vw 2.0833333333vw;
  }
  .staff__wrap img {
    height: 20.8333333333vw;
    object-position: top;
  }
  .staff__inner {
    padding: 0.5555555556vw;
    border-radius: 0.2777777778vw;
  }
  .staff__position {
    margin-top: 0.4166666667vw;
    font-size: 0.8333333333vw;
  }
  .staff__info {
    margin-top: 0.2777777778vw;
  }
  .staff__info a img {
    height: 2.0833333333vw;
    width: 2.0833333333vw;
  }
  .staff__name--ja {
    font-size: 1.1111111111vw;
  }
  .staff__name--en {
    font-size: 0.8333333333vw;
  }
  .staff__message--ttl {
    margin-top: 0.6944444444vw;
    font-size: 0.9722222222vw;
  }
  .staff__message--txt {
    margin-top: 0.4166666667vw;
    font-size: 0.9722222222vw;
  }
  .staff__message--inner {
    margin-bottom: 1.1111111111vw;
  }
  .staff__message.active {
    margin-top: 1.3888888889vw;
  }
  .staff__view {
    font-size: 0.9722222222vw;
  }
}
.style__wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8vw 4vw;
}
.style__item {
  position: relative;
  height: 61.3333333333vw;
}
.style__item div {
  height: 61.3333333333vw;
  background-color: #fff;
  border-radius: 1.0666666667vw;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  padding: 2.1333333333vw;
}
.style__item--front, .style__item--back {
  transition: all 0.6s;
  position: absolute;
  left: 0;
  top: 0;
  backface-visibility: hidden;
}
.style__item--back {
  transform: rotateY(180deg);
}
.style__item.active .style__item--front {
  transform: rotateY(180deg);
}
.style__item.active .style__item--back {
  transform: rotateY(0);
}
.style__item img {
  height: 100%;
  width: 100%;
}
.style__note {
  font-size: 2.6666666667vw;
  color: #606060;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  .style__wrap {
    width: 62.5vw;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.0833333333vw 2.0833333333vw;
  }
  .style__item {
    height: 17.3611111111vw;
  }
  .style__item div {
    height: 17.3611111111vw;
    border-radius: 0.2777777778vw;
    padding: 0.5555555556vw;
  }
  .style__item--front, .style__item--back {
    width: -webkit-fill-available;
  }
  .style__note {
    margin-top: 0.2777777778vw;
    font-size: 0.6944444444vw;
  }
}
/* ---------------------------------------- */
/*  base                                    */
/* ---------------------------------------- */
.top {
  padding: 26.6666666667vw 5.3333333333vw 26.6666666667vw;
}
.top .section + .section {
  margin-top: 21.3333333333vw;
}
.top .section__btn {
  margin-top: 8vw;
}
.top .section__ttl {
  position: relative;
}
.top .section__ttl h2 {
  font-size: 7.4666666667vw;
  padding-left: 2.1333333333vw;
  border-left: 5px solid #7b5544;
}
.top .section__ttl p {
  font-size: 16vw;
  font-weight: bold;
  color: #f2f2f2;
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  z-index: -1;
}
.top .section__ttl p::first-letter {
  text-transform: uppercase;
}
.top .top-concept-swiper {
  overflow-x: hidden;
}
.top .top-style-concept-swiper {
  overflow-x: hidden;
}

/* ---------------------------------------- */
/*  kv                                      */
/* ---------------------------------------- */
.kv {
  width: 100%;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@media only screen and (min-width: 768px) {
  /* ---------------------------------------- */
  /*  base                                    */
  /* ---------------------------------------- */
  .top {
    max-width: 75vw;
    margin: 0 auto;
    padding: 6.9444444444vw 0 6.9444444444vw;
  }
  .top .section + .section {
    margin-top: 8.3333333333vw;
  }
  .top .section__btn {
    width: 15.625vw;
    margin-left: auto;
    margin-top: auto;
  }
  .top .section__ttl h2 {
    font-size: 2.2222222222vw;
    padding-left: 0.6944444444vw;
    border-left: 0.5555555556vw solid #7b5544;
    line-height: 1.2;
  }
  .top .section__ttl p {
    font-size: 6.9444444444vw;
    width: 100%;
    margin: 0;
    line-height: 1;
    top: 0;
    right: 0;
  }
  /* ---------------------------------------- */
  /*  kv                                      */
  /* ---------------------------------------- */
  .kv {
    width: 100%;
  }
  .kv img {
    height: 100%;
  }
}