@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.privacy {
  &__wrap {
    h2 {
      font-size: f.size(18);
      font-weight: bold;
      color: #434343;
    }
    h3 {
      font-size: f.size(16);
      font-weight: bold;
      color: #434343;
    }
    p {
      font-size: f.size(14);
      color: #606060;
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .privacy {
    &__wrap {
      h2 {
        font-size: f.size(18, 1440);
      }
      h3 {
        font-size: f.size(16, 1440);
      }
      p {
        font-size: f.size(14, 1440);
      }
    }
  }
}
