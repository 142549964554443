@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.p-recruit {
  &__wrap {
    margin-top: f.size(30);
    img {
      @include m.over;
      height: f.size(126);
      object-position: top;
    }
  }
  &__txt {
    margin-top: f.size(20);
    text-align: center;
    font-size: f.size(16);
  }
  &__important {
    margin-top: f.size(20);
    text-align: center;
    &--list {
      padding: 0 f.size(10);
      margin-top: f.size(16);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: f.size(10);
      li {
        height: f.size(70);
        border-radius: f.size(4);
        background-color: #fff;
        filter: drop-shadow(3px 3px 6px rgba(#000, 16%));
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: f.size(14);
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:nth-of-type(1)::after {
          height: f.size(50);
          width: f.size(50);
          background: url('../img/balance.png') no-repeat;
          background-size: cover;
          z-index: -1;
          opacity: 0.4;
        }
        &:nth-of-type(2)::after {
          height: f.size(80);
          width: f.size(80);
          background: url('../img/human.png') no-repeat;
          background-size: cover;
          z-index: -1;
          opacity: 0.4;
        }
        &:nth-of-type(3)::after {
          height: f.size(50);
          width: f.size(66);
          background: url('../img/time.png') no-repeat;
          background-size: cover;
          z-index: -1;
          opacity: 0.2;
        }
        &:nth-of-type(4)::after {
          height: f.size(56);
          width: f.size(80);
          background: url('../img/up.png') no-repeat;
          background-size: cover;
          z-index: -1;
          opacity: 0.2;
        }
      }
    }
  }
  &__btn {
    margin-top: f.size(20);
    button {
      border-radius: f.size(4);
    }
  }
  &__note {
    margin-top: f.size(8);
    font-size: f.size(14);
    a {
      text-decoration: underline;
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .p-recruit {
    &__wrap {
      margin-top: f.size(50, 1440);
      img {
        @include m.over;
        height: f.size(300, 1440);
        object-position: 0 calc(-1 * f.size(100, 1440));
      }
    }
    &__txt {
      margin-top: f.size(40, 1440);
      font-size: f.size(20, 1440);
    }
    &__important {
      margin-top: f.size(20, 1440);
      font-size: f.size(22, 1440);
      &--list {
        max-width: f.size(800, 1920);
        margin: 0 auto;
        padding: 0 f.size(20, 1440);
        margin-top: f.size(20, 1440);
        gap: f.size(10, 1440);
        li {
          height: f.size(80, 1440);
          border-radius: f.size(4, 1440);
          font-size: f.size(16, 1440);
          &:nth-of-type(1)::after {
            height: f.size(50, 1440);
            width: f.size(50, 1440);
          }
          &:nth-of-type(2)::after {
            height: f.size(80, 1440);
            width: f.size(80, 1440);
          }
          &:nth-of-type(3)::after {
            height: f.size(50, 1440);
            width: f.size(66, 1440);
          }
          &:nth-of-type(4)::after {
            height: f.size(56, 1440);
            width: f.size(80, 1440);
          }
        }
      }
    }
    div.section__btn {
      margin: 0 auto;
      margin-top: f.size(40, 1440);
    }
    div.p-recruit__btn {
      max-width: f.size(500, 1920);
      margin: 0 auto;
      margin-top: f.size(40, 1440);
      .c-btn.line {
        height: f.size(80, 1920);
        border-radius: f.size(8, 1920);
        a {
          font-size: f.size(22, 1920);
          transition: 0.3s;
          img {
            width: f.size(55, 1920);
            height: f.size(55, 1920);
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    &__note {
      margin-top: f.size(8, 1440);
      font-size: f.size(14, 1440);
    }
  }
}
