@use '../../foundation/mixin' as m;
@use '../../foundation/function' as f;

.c-info {
  display: grid;
  gap: f.size(16);
  font-size: f.size(14);
  &__ttl {
    border-bottom: 1px solid #bababa;
  }
  &__detail {
    padding-left: 1em;
    margin-top: f.size(8);
    line-height: 1.5;
  }
  &__map {
    margin-top: f.size(16);
    iframe {
      width: 100%;
      height: f.size(300);
    }
  }
  &__btn {
    margin-top: f.size(16);
    a {
      text-transform: none;
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .c-info {
    display: grid;
    gap: 0;
    font-size: f.size(14, 1440);
    &__wrap {
      display: grid;
      grid-template-columns: f.size(80, 1440) 1fr;
      align-items: center;
      gap: 0 f.size(100, 1440);
      padding: f.size(30, 1440) f.size(20, 1440);
      border-top: 1px solid #bababa;
      &:last-of-type {
        border-bottom: 1px solid #bababa;
      }
    }
    &__ttl {
      border-bottom: none;
    }
    &__detail {
      padding-left: 0;
      margin-top: 0;
    }
    &__map {
      grid-column: 1 / 3;
      margin-top: f.size(30, 1440);
      iframe {
        width: 100%;
        height: f.size(500, 1440);
      }
    }
    &__btn {
      text-align: center;
      grid-column: 1 / 3;
      margin-top: f.size(30, 1440);
      .c-btn {
        width: f.size(300, 1440);
      }
    }
  }
}
