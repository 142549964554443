@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.p-style {
  &__wrap {
    padding-bottom: f.size(30);
    margin-top: f.size(30);
    @include m.shadow;
    position: relative;
  }
  &__img {
    padding: f.size(10);
    background-color: #fff;
    img {
      height: f.size(380);
    }
  }
  .swiper-pagination {
    bottom: 0;
    .swiper-pagination-bullet {
      background: #ffffff;
      border: 1px solid#434343;
      width: f.size(10);
      height: f.size(10);
      &.swiper-pagination-bullet-active {
        background-color: #434343;
      }
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .p-style {
    &__wrap {
      padding-bottom: f.size(40, 1440);
      margin-top: f.size(60, 1440);
    }
    &__img {
      padding: f.size(10, 1440);
      img {
        height: f.size(380, 1440);
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: f.size(10, 1440);
        height: f.size(10, 1440);
      }
    }
    div.section__btn {
      margin: 0 auto;
      margin-top: f.size(40, 1440);
    }
  }
}
