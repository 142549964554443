@use '../../foundation/mixin' as m;
@use '../../foundation/function' as f;

.page-ttl {
  padding-top: f.size(40);
  position: relative;
  h1 {
    font-size: f.size(28);
    font-weight: bold;
    position: relative;
    &::before {
      content: '';
      height: f.size(3);
      width: 50%;
      background-color: #7b5544;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    &::after {
      content: '';
      height: f.size(3);
      width: f.size(40);
      background-color: #5f651e;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
  p {
    font-size: f.size(60);
    font-weight: bold;
    color: #f2f2f2;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.page-subTtl {
  font-size: f.size(24);
  font-weight: bold;
  position: relative;
  display: inline-block;
  line-height: 1;
  color: #222;
  &::after {
    content: '';
    height: f.size(1);
    width: 100%;
    background-color: #222;
    position: absolute;
    bottom: calc(-1 * f.size(2));
    left: 0;
  }
  &.over {
    white-space: nowrap;
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .page-ttl {
    padding-top: f.size(40, 1440);
    position: relative;
    h1 {
      font-size: f.size(28, 1440);
      font-weight: bold;
      position: relative;
      &::before {
        content: '';
        height: f.size(3, 1440);
        width: 50%;
        background-color: #7b5544;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
      &::after {
        content: '';
        height: f.size(3, 1440);
        width: f.size(40, 1440);
        background-color: #5f651e;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
      }
    }
    p {
      font-size: f.size(60, 1440);
      font-weight: bold;
      color: #f2f2f2;
      position: absolute;
      top: 0;
      right: 0;
      line-height: 1;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .page-subTtl {
    font-size: f.size(24, 1440);
    font-weight: bold;
    position: relative;
    display: inline-block;
    line-height: 1;
    color: #222;
    &::after {
      content: '';
      height: f.size(1, 1440);
      width: 100%;
      background-color: #222;
      position: absolute;
      bottom: calc(-1 * f.size(2, 1440));
      left: 0;
    }
    &.over {
      white-space: nowrap;
    }
  }
}
