@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.p-staff {
  &__wrap {
    margin-top: f.size(30);
    img {
      width: 100%;
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .p-staff {
    &__wrap {
      margin-top: f.size(50, 1440);
      img {
        width: 100%;
        height: auto;
      }
    }
    div.section__btn {
      margin: 0 auto;
      margin-top: f.size(40, 1440);
    }
  }
}
