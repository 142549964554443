@use '../foundation/function' as f;
@use '../foundation/mixin' as m;

.l-nav {
  inset: 0;
  transform: translate(100%, 0);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  background-color: #fafafa;
  z-index: 500;
  padding: f.size(80) f.size(20) f.size(20);
  transition: transform 0.3s;
  &__logo {
    display: block;
    height: f.size(90);
    width: f.size(90);
    margin: 0 auto;
  }
  &__list {
    margin-top: f.size(20);
    display: grid;
    gap: f.size(16);
    text-align: center;
  }
  &__recruit {
    width: f.size(300);
    margin: 0 auto;
    margin-top: f.size(16);
    p {
      font-size: f.size(18);
      position: relative;
      &::after {
        content: '';
        height: 1px;
        width: 100%;
        background-color: #222;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .c-btn__wrap {
      margin-top: f.size(16);
      &:last-of-type {
        margin-top: f.size(8);
      }
    }
  }
  &__sns {
    margin-top: f.size(16);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: f.size(16);
    li {
      &:first-of-type {
        width: f.size(30);
        height: f.size(30);
      }
      &:last-of-type {
        width: f.size(30);
        height: f.size(30);
      }
    }
    a {
      display: block;
    }
  }
  &__footer {
    margin-top: f.size(50);
    &--list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        width: calc(100% / 4);
        text-align: center;
        a {
          font-size: f.size(14);
          color: #fff;
        }
      }
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .l-nav__footer {
    margin-top: f.size(50, 1920);
    &--list {
      flex-wrap: nowrap;
      gap: f.size(30, 1920);
      li {
        width: auto;
        a {
          font-size: f.size(18, 1920);
          color: #fff;
        }
      }
    }
  }
}
