@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.news {
  &__wrap {
    time {
      font-size: f.size(10);
      color: #bababa;
    }
    &:nth-of-type(n + 2) {
      margin-top: f.size(30);
      padding-top: f.size(30);
      border-top: 1px solid #c3c3c3;
    }
  }
  &__ttl {
    font-size: f.size(16);
    color: #434343;
    font-weight: bold;
  }
  &__content {
    background-color: #fff;
    @include m.shadow;
    height: 0;
    visibility: hidden;
    opacity: 0;
    p {
      font-size: f.size(12);
      @include m.lineHeight(21, 12);
      color: #606060;
      &.news__view {
        color: #5f651e;
        position: absolute;
        bottom: f.size(16);
        left: 50%;
        transform: translateX(-50%);
      }
      & + figure {
        margin: f.size(16) auto;
      }
    }
    figure {
      & + p {
        margin-top: f.size(16);
      }
    }
    img {
      display: block;
      margin: 0 auto;
      width: 80%;
      aspect-ratio: 3 / 2;
    }
    &.active {
      margin-top: f.size(8);
      padding: f.size(20);
      padding-bottom: f.size(80);
      height: auto;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
  &__view {
    display: inline-block;
    margin-top: f.size(8);
    color: #5f651e;
    font-weight: bold;
    font-size: f.size(12);
    text-decoration: underline;
    text-decoration-color: inherit;
    cursor: pointer;
    &::first-letter {
      text-transform: uppercase;
    }
    &.active {
      display: none;
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .news {
    &__wrap {
      time {
        font-size: f.size(12, 1440);
      }
      &:nth-of-type(n + 2) {
        margin-top: f.size(30, 1440);
        padding-top: f.size(30, 1440);
      }
    }
    &__ttl {
      font-size: f.size(20, 1440);
    }
    &__content {
      p {
        font-size: f.size(16, 1440);
        &.news__view {
          bottom: f.size(16, 1440);
        }
        & + figure {
          margin: f.size(20, 1440) auto;
        }
      }
      figure {
        & + p {
          margin-top: f.size(20, 1440);
        }
      }
      img {
        width: 50%;
      }
      &.active {
        margin-top: f.size(32, 1440);
        padding: f.size(40, 1440) f.size(120, 1440);
        padding-bottom: f.size(80, 1440);
      }
    }
    &__view {
      margin-top: f.size(10, 1440);
      font-size: f.size(14, 1440);
    }
  }
}
