@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.recruit {
  &__top {
    color: #434343;
    text-align: center;
    h2 {
      font-size: f.size(20);
      font-weight: bold;
    }
    p {
      margin-top: f.size(20);
      font-size: f.size(14);
    }
  }
  &__nav {
    margin-top: f.size(50);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: f.size(8) f.size(16);
    li {
      height: f.size(40);
      border: 1px solid #707070;
      a {
        font-size: f.size(14);
        color: #434343;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
  &__btn {
    margin-top: f.size(16);
    @include m.shadow;
  }
  &__greeting {
    &--wrap {
      margin-top: f.size(24);
      img {
        width: 100%;
        height: f.size(350);
        object-position: top;
      }
    }
    &--txt {
      margin-top: f.size(8);
    }
    &--name {
      font-size: f.size(16);
      font-weight: bold;
    }
    &--message {
      margin-top: f.size(8);
      font-size: f.size(16);
      color: #434343;
    }
  }
  &__important {
    &--list {
      margin-top: f.size(24);
      display: grid;
      gap: f.size(20);
    }
    &--item {
      h3 {
        font-size: f.size(16);
        font-weight: bold;
      }
      div {
        margin-top: f.size(4);
        padding: f.size(8) f.size(16);
        border-radius: f.size(4);
        position: relative;
        min-height: f.size(170);
        ul {
          position: relative;
          z-index: 10;
          li {
            padding-left: f.size(12);
            font-size: f.size(12);
            color: #606060;
            position: relative;
            &::before {
              content: '';
              height: f.size(3);
              width: f.size(3);
              border-radius: 50%;
              background-color: #606060;
              position: absolute;
              top: f.size(10);
              left: 0;
            }
            &:nth-of-type(n + 2) {
              margin-top: f.size(4);
            }
          }
        }
        &::after {
          content: '';
          position: absolute;
          bottom: f.size(20);
          right: f.size(20);
        }
        &.balance {
          background-color: #fbebff;
          &::after {
            background: url('../img/balance.png') no-repeat;
            height: f.size(100);
            width: f.size(100);
            background-size: cover;
            opacity: 0.3;
            z-index: 5;
          }
        }
        &.human {
          background-color: #ffe9d3;
          &::after {
            background: url('../img/human.png') no-repeat;
            height: f.size(120);
            width: f.size(120);
            background-size: cover;
            opacity: 0.3;
            z-index: 5;
            bottom: 0;
            right: f.size(15);
          }
        }
        &.time {
          background-color: #e6ffef;
          &::after {
            background: url('../img/time.png') no-repeat;
            height: f.size(100);
            width: f.size(132);
            background-size: cover;
            opacity: 0.2;
            z-index: 5;
            right: 0;
          }
        }
        &.growth {
          background-color: #d3fdff;
          &::after {
            background: url('../img/up.png') no-repeat;
            height: f.size(100);
            width: f.size(140);
            background-size: cover;
            opacity: 0.2;
            z-index: 5;
          }
        }
      }
    }
  }
  &__demand {
    &--list {
      margin-top: f.size(24);
      li {
        padding-left: f.size(12);
        font-size: f.size(16);
        color: #434343;
        position: relative;
        &::before {
          content: '';
          height: f.size(3);
          width: f.size(3);
          border-radius: 50%;
          background-color: #434343;
          position: absolute;
          top: f.size(14);
          left: 0;
        }
      }
    }
  }
  &__recruitment {
    &--content {
      margin-top: f.size(24);
    }
    &--wrap {
      &:nth-of-type(n + 2) {
        margin-top: f.size(16);
      }
    }
    &--ttl {
      font-size: f.size(16);
      font-weight: bold;
      border-bottom: 1px solid #bababa;
    }
    &--detail {
      p {
        font-size: f.size(14);
        color: #606060;
        padding-left: f.size(14);
        &.mark {
          position: relative;
          color: #434343;
          &::before {
            content: '';
            height: f.size(10);
            width: f.size(10);
            border-radius: 50%;
            background-color: #8db8be;
            position: absolute;
            top: f.size(8);
            left: 0;
          }
        }
      }
    }
    .p-recruit__btn {
      margin-top: f.vw(30);
    }
  }
  &__line {
    &--message {
      margin-top: f.size(24);
      font-size: f.size(16);
    }
    &--apply {
      margin-top: f.size(16);
      ul {
        li {
          padding-left: f.size(12);
          font-size: f.size(16);
          color: #434343;
          position: relative;
          &::before {
            content: '';
            height: f.size(3);
            width: f.size(3);
            border-radius: 50%;
            background-color: #434343;
            position: absolute;
            top: f.size(14);
            left: 0;
          }
        }
      }
    }
    &--link {
      margin-top: f.size(16);
      font-weight: bold;
      font-size: f.size(16);
      white-space: nowrap;
      a {
        color: #6cc1ff;
        text-decoration: underline;
      }
    }
    .c-btn {
      height: f.size(55);
      border-radius: f.size(4);
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .recruit {
    &__top {
      h2 {
        font-size: f.size(24, 1440);
      }
      p {
        margin-top: f.size(20, 1440);
        font-size: f.size(18, 1440);
      }
    }
    &__nav {
      margin-top: f.size(50, 1440);
      gap: f.size(16, 1440) f.size(16, 1440);
      li {
        height: f.size(40, 1440);
        a {
          font-size: f.size(16, 1440);
        }
      }
    }
    &__btn {
      margin-top: f.size(16, 1440);
      .c-btn {
        height: f.size(60, 1440);
        a {
          font-size: f.size(16, 1440);
        }
      }
    }
    &__greeting {
      margin: 0 auto;
      margin-top: f.size(100, 1440);
      width: f.size(900, 1440);
      &--wrap {
        display: flex;
        justify-content: center;
        gap: f.size(50, 1440);
        margin-top: f.size(30, 1440);
        img {
          width: 30%;
          height: f.size(350, 1440);
        }
        &:nth-of-type(even) {
          flex-direction: row-reverse;
        }
        &:nth-of-type(n + 2) {
          margin-top: f.size(60, 1440);
        }
      }
      &--txt {
        margin-top: 0;
      }
      &--name {
        font-size: f.size(20, 1440);
      }
      &--message {
        margin-top: f.size(12, 1440);
        font-size: f.size(16, 1440);
      }
    }
    &__important {
      &--list {
        margin-top: f.size(30, 1440);
        grid-template-columns: repeat(2, 1fr);
        gap: f.size(30, 1440);
      }
      &--item {
        h3 {
          font-size: f.size(20, 1440);
        }
        div {
          margin-top: f.size(8, 1440);
          padding: f.size(20, 1440);
          border-radius: f.size(4, 1440);
          min-height: f.size(200, 1440);
          ul {
            li {
              padding-left: f.size(16, 1440);
              font-size: f.size(14, 1440);
              &::before {
                height: f.size(4, 1440);
                width: f.size(4, 1440);
                top: 0.8em;
              }
              &:nth-of-type(n + 2) {
                margin-top: f.size(8, 1440);
              }
            }
          }
          &::after {
            bottom: f.size(20, 1440);
            right: f.size(20, 1440);
          }
          &.balance {
            &::after {
              height: f.size(100, 1440);
              width: f.size(100, 1440);
            }
          }
          &.human {
            &::after {
              height: f.size(120, 1440);
              width: f.size(120, 1440);
              right: f.size(15, 1440);
            }
          }
          &.time {
            &::after {
              height: f.size(100, 1440);
              width: f.size(132, 1440);
            }
          }
          &.growth {
            &::after {
              height: f.size(100, 1440);
              width: f.size(140, 1440);
            }
          }
        }
      }
    }
    &__demand {
      &--list {
        margin-top: f.size(24, 1440);
        li {
          padding-left: f.size(14, 1440);
          font-size: f.size(16, 1440);
          &::before {
            height: f.size(4, 1440);
            width: f.size(4, 1440);
            top: f.size(14, 1440);
          }
        }
      }
    }
    &__recruitment {
      &--content {
        width: f.size(800, 1440);
        margin: 0 auto;
        margin-top: f.size(30, 1440);
      }
      &--wrap {
        display: flex;
        align-items: center;
        padding: f.size(20, 1440);
        border-top: 1px solid #bababa;
        &:nth-of-type(n + 2) {
          margin-top: 0;
        }
        &:last-of-type {
          border-bottom: 1px solid #bababa;
        }
      }
      &--ttl {
        font-size: f.size(16, 1440);
        font-weight: bold;
        border-bottom: none;
        min-width: f.size(100, 1440);
        align-self: flex-start;
      }
      &--detail {
        p {
          font-size: f.size(14, 1440);
          color: #606060;
          padding-left: f.size(14, 1440);
          &.mark {
            position: relative;
            color: #434343;
            &::before {
              content: '';
              height: f.size(10, 1440);
              width: f.size(10, 1440);
              border-radius: 50%;
              background-color: #8db8be;
              position: absolute;
              top: f.size(8, 1440);
              left: 0;
            }
          }
        }
      }
      .p-recruit__btn {
        width: f.size(800, 1440);
        margin: 0 auto;
        margin-top: f.size(30, 1440);
        .c-btn {
          height: f.size(60, 1440);
          border-radius: f.size(4, 1440);
          a {
            font-size: f.size(18, 1440);
          }
        }
      }
    }
    &__line {
      &--message {
        margin-top: f.size(24, 1440);
        font-size: f.size(16, 1440);
      }
      &--apply {
        width: f.size(800, 1440);
        margin: 0 auto;
        margin-top: f.size(16, 1440);
        p {
          font-size: f.size(16, 1440);
        }
        ul {
          li {
            padding-left: f.size(12, 1440);
            font-size: f.size(18, 1440);
            &::before {
              height: f.size(4, 1440);
              width: f.size(4, 1440);
              top: f.size(14, 1440);
            }
          }
        }
      }
      &--link {
        width: f.size(800, 1440);
        margin: 0 auto;
        margin-top: f.size(20, 1440);
        font-size: f.size(16, 1440);
      }
      .c-btn {
        height: f.size(60, 1440);
        border-radius: f.size(4, 1440);
        a {
          font-size: f.size(18, 1440);
        }
      }
    }
    &__btn {
      width: f.size(800, 1440);
      margin: 0 auto;
      margin-top: f.size(30, 1440);
    }
  }
}
