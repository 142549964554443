@use '../foundation/function' as f;
@use '../foundation/mixin' as m;

.u-mt-l {
  margin-top: f.size(80);
}
.u-mt-m {
  margin-top: f.size(30);
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .u-mt-l {
    margin-top: f.size(80, 1440);
  }
  .u-mt-m {
    margin-top: f.size(30, 1440);
  }
}
