@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.menu {
  &__message {
    font-size: f.size(20);
    font-weight: bold;
    text-align: center;
  }
  &__nav {
    margin-top: f.size(50);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: f.size(12);
    &--item {
      a {
        padding: f.size(2) 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: f.size(16);
        font-weight: bold;
        line-height: 1;
        position: relative;
        text-transform: uppercase;
        color: #434343;
        &::after {
          content: attr(data-ja);
          font-size: f.size(10);
          line-height: 1;
        }
      }
      &:nth-of-type(2),
      &:nth-of-type(5),
      &:nth-of-type(8) {
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
      }
    }
  }
  &__name {
    font-size: f.size(22);
    font-weight: bold;
    color: #606060;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    span {
      font-size: f.size(12);
      margin-left: f.size(8);
      font-weight: normal;
    }
  }
  &__txt {
    margin-top: f.size(16);
    font-size: f.size(14);
    line-height: 1.5;
    color: #434343;
  }
  &__list {
    margin-top: f.size(16);
    display: grid;
    gap: f.size(16);
    li {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
      align-items: center;
      padding-bottom: f.size(8);
      border-bottom: f.size(1) solid #606060;
      gap: f.size(20);
    }
  }
  &__detail {
    font-size: f.size(16);
    color: #606060;
    display: grid;
    span {
      font-size: f.size(12);
      line-height: 1;
    }
  }
  &__price {
    font-size: f.size(16);
    font-weight: bold;
    color: #606060;
  }
  &__note {
    font-size: f.size(12);
    color: #606060;
    text-align: right;
  }
  .coupon__btn {
    width: 100%;
    margin-top: f.vw(20);
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .menu {
    &__message {
      font-size: f.size(20, 1440);
    }
    &__nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: f.size(50, 1440);
      row-gap: f.size(16, 1440);
      &--item {
        width: f.size(250, 1440);
        a {
          padding: f.size(2, 1440) 0;
          font-size: f.size(18, 1440);
          gap: f.size(4, 1440);
          &::after {
            font-size: f.size(12, 1440);
          }
        }
        &:last-of-type {
          grid-column: unset;
        }
        &:nth-of-type(2),
        &:nth-of-type(5),
        &:nth-of-type(7) {
          border: none;
        }
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          border-left: 1px solid #707070;
        }
      }
    }
    &__wrap {
      width: f.size(800, 1440);
      margin: 0 auto;
      margin-top: f.size(60, 1440);
    }
    &__name {
      font-size: f.size(24, 1440);
      span {
        font-size: f.size(14, 1440);
        margin-left: f.size(8, 1440);
      }
    }
    &__txt {
      margin-top: f.size(16, 1440);
      font-size: f.size(16, 1440);
      line-height: 2;
    }
    &__list {
      margin-top: f.size(20, 1440);
      display: grid;
      gap: f.size(16, 1440);
      li {
        gap: f.size(30, 1440);
        padding-bottom: f.size(8, 1440);
        border-bottom: f.size(1, 1440) solid #606060;
      }
    }
    &__detail {
      font-size: f.size(16, 1440);
      span {
        font-size: f.size(14, 1440);
      }
    }
    &__price {
      font-size: f.size(16, 1440);
    }
    &__note {
      font-size: f.size(12, 1440);
    }
    .coupon__btn {
      max-width: f.size(900, 1440);
      margin: 0 auto;
      margin-top: f.vw(40, 1440);
    }
  }
}
