@use '../../foundation/mixin' as m;
@use '../../foundation/function' as f;

.c-pager {
  margin-top: f.vw(80);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: f.vw(30);
  .page-numbers {
    height: f.vw(50);
    width: f.vw(50);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
    border: 1px solid #111;
    border-radius: 50%;
    color: #fff;
    font-size: f.vw(16);
    &.current {
      color: #111;
      background-color: #fff;
    }
    &.dots {
      width: f.vw(6);
      background-color: transparent;
      border: none;
      color: #111;
    }
  }
  .next,
  .prev {
    height: f.vw(50);
    width: f.vw(50);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
  }
  .prev {
    span {
      width: f.vw(12);
      height: f.vw(12);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: translateX(f.vw(2)) rotate(-135deg);
    }
  }
  .next {
    span {
      width: f.vw(12);
      height: f.vw(12);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: translateX(calc(-1 * f.vw(2))) rotate(45deg);
    }
  }
}
@include m.media(for-sp) {
  .c-pager {
    margin-top: f.vw(50, 375);
    gap: f.vw(12, 375);
    .page-numbers {
      height: f.vw(35, 375);
      width: f.vw(35, 375);
      font-size: f.vw(16);
      &.dots {
        width: f.vw(6, 375);
      }
    }
    .next,
    .prev {
      height: f.vw(35, 375);
      width: f.vw(35, 375);
    }
    .prev {
      span {
        width: f.vw(10, 375);
        height: f.vw(10, 375);
        transform: translateX(f.vw(2, 375)) rotate(-135deg);
      }
    }
    .next {
      span {
        width: f.vw(10, 375);
        height: f.vw(10, 375);
        transform: translateX(calc(-1 * f.vw(2, 375))) rotate(45deg);
      }
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
}
