@use '../foundation/function' as f;
@use '../foundation/mixin' as m;

.l-inner {
  padding: f.size(60) f.size(20) f.size(100);
}

.l-content-inner {
  margin-top: f.size(50);
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .pc-inner {
    max-width: f.size(1200, 1920);
    margin: 0 auto;
  }
  .l-inner {
    padding: f.size(180, 1920) f.size(240, 1920) f.size(100, 1920);
  }
  .l-content-inner {
    margin-top: f.size(80, 1920);
  }
}
