@use '../../foundation/mixin' as m;
@use '../../foundation/function' as f;

.c-instagram {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
}
