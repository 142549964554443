@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.p-concept {
  &__wrap {
    margin-top: f.size(40);
    text-align: center;
    img {
      width: f.size(120);
    }
  }
  &__philosophy {
    font-size: f.size(16);
    font-weight: bold;
    margin-top: f.size(30);
  }
  &__txt {
    margin-top: f.size(16);
    font-size: f.size(12);
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .p-concept {
    &__wrap {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, auto);
      gap: 0 f.size(40, 1440);
      margin-top: f.size(50, 1440);
      margin-left: 0;
      text-align: left;
      img {
        height: f.size(300, 1920);
        width: auto;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }
      .pc-flex-col {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
      }
      .section__btn {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        margin-top: 0 !important;
      }
      .p-concept__insta {
        grid-row: 1 / 3;
        grid-column: 3 / 4;
      }
    }
    &__philosophy {
      font-size: f.size(20, 1440);
      margin-top: f.size(10, 1920);
    }
    &__txt {
      font-size: f.size(16, 1440);
      margin-top: f.size(18, 1920);
    }
  }
}
