@use '../../foundation/function' as f;
@use '../../foundation/mixin' as m;

.p-menu {
  &__wrap {
    margin-top: f.size(30);
  }
  &__list {
    display: grid;
    gap: f.size(8);
    li {
      display: flex;
      justify-content: space-between;
    }
  }
  &__name {
    font-size: f.size(16);
  }
  &__price {
    font-size: f.size(16);
    margin-left: f.vw(20);
  }
  &__note {
    margin-top: f.size(8);
    font-size: f.size(10);
    color: #606060;
    text-align: right;
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .p-menu {
    &__wrap {
      max-width: f.size(800, 1920);
      margin: 0 auto;
      margin-top: f.size(40, 1440);
    }
    &__list {
      gap: f.size(16, 1440);
    }
    &__name {
      font-size: f.size(18, 1440);
    }
    &__price {
      font-size: f.size(18, 1440);
      margin-left: f.vw(30, 1440);
    }
    &__note {
      margin-top: f.size(8, 1440);
      font-size: f.size(14, 1440);
    }
    div.section__btn {
      margin: 0 auto;
      margin-top: f.size(40, 1440);
    }
  }
}
