@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.concept {
  .page-subTtl {
    left: 50%;
    transform: translateX(-50%);
  }
  &__top {
    color: #434343;
    position: relative;
    &--philosophy {
      margin-top: f.size(20);
      font-size: f.size(18);
      line-height: 2;
      text-align: center;
      font-weight: bold;
    }
    &--value {
      margin-top: f.size(20);
      font-size: f.size(14);
      padding-left: f.size(20);
      list-style: decimal;
      li {
        padding-left: f.size(4);
        list-style: inherit;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: f.size(200);
      height: f.size(200);
      background: url('../img/logo-circle.png') no-repeat;
      background-size: cover;
      opacity: 0.1;
    }
  }
  &__salon {
    &--img {
      position: relative;
      margin-top: f.size(20);
      padding-bottom: f.size(30);
      @include m.over;
      .swiper-slide {
        text-align: center;
        img {
          height: f.size(180);
          width: f.size(335);
        }
      }
      .top-concept-swiper-pagination,
      .top-style-swiper-pagination {
        position: absolute;
        text-align: center;
        transition: 0.3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
      }
      .top-concept-swiper-pagination,
      .top-style-swiper-pagination,
      .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
          background: #ffffff;
          border: 1px solid#434343;
          width: f.size(10);
          height: f.size(10);
          &.swiper-pagination-bullet-active {
            background-color: #434343;
          }
        }
      }
    }
  }
  &__point {
    display: grid;
    gap: f.size(16);
    &--wrap {
      background-color: #fff;
      border-radius: f.size(4);
      @include m.shadow;
      padding: f.size(16);
      &:first-of-type {
        margin-top: calc(-1 * f.size(8));
      }
    }
    h3 {
      font-size: f.size(18);
      font-weight: bold;
    }
    h4 {
      font-size: f.size(14);
      span {
        font-size: f.size(18);
        margin-right: f.size(4);
      }
    }
    p {
      font-size: f.size(12);
      color: #434343;
    }
    &.obsession {
      h3,
      h4 {
        color: #eaa587;
      }
    }
    &.safe {
      h3,
      h4 {
        color: #8db8be;
      }
    }
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .concept {
    .page-subTtl {
      left: 50%;
      transform: translateX(-50%);
    }
    &__top {
      color: #434343;
      position: relative;
      &--philosophy {
        margin-top: f.size(30, 1440);
        font-size: f.size(30, 1440);
        line-height: 2;
        text-align: center;
        font-weight: bold;
      }
      &--value {
        margin: 0 auto;
        margin-top: f.size(30, 1440);
        font-size: f.size(22, 1440);
        width: 1000px;
        padding-left: 0;
        li {
          padding-left: f.size(8, 1440);
        }
      }
      &::after {
        width: f.size(400, 1440);
        height: f.size(400, 1440);
      }
    }
    &__salon {
      &--img {
        margin-top: f.size(40, 1440);
        padding-bottom: f.size(40, 1440);
        .swiper-slide {
          img {
            height: f.size(180, 1440);
            width: f.size(335, 1440);
          }
        }
        .top-concept-swiper-pagination,
        .top-style-swiper-pagination,
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: f.size(10, 1440);
            height: f.size(10, 1440);
          }
        }
      }
    }
    &__point {
      gap: f.size(18, 1440);
      &--wrap {
        border-radius: f.size(4, 1440);
        padding: f.size(20, 1440);
        &:first-of-type {
          margin-top: calc(-1 * f.size(12, 1440));
        }
      }
      h3 {
        font-size: f.size(20, 1440);
      }
      h4 {
        font-size: f.size(16, 1440);
        span {
          font-size: f.size(20, 1440);
          margin-right: f.size(6, 1440);
        }
      }
      p {
        font-size: f.size(14, 1440);
      }
    }
  }
}
