@use '../foundation/function' as f;
@use '../foundation/mixin' as m;

.l-footer {
  padding: f.size(40) f.size(20) f.size(20);
  background-color: #8db8be;
  text-align: center;
  &__logo {
    margin: 0 auto;
    display: inline-grid;
    justify-content: center;
    gap: f.size(10);
    img {
      &:first-of-type {
        justify-self: center;
        width: f.size(80);
        height: f.size(80);
      }
      &:last-of-type {
        width: f.size(150);
      }
    }
  }
  &__sns {
    margin-top: f.size(10);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: f.size(16);
    img {
      &:first-of-type {
        width: f.size(30);
        height: f.size(30);
      }
      &:last-of-type {
        width: f.size(30);
        height: f.size(30);
      }
    }
  }
  &__copy {
    display: block;
    margin-top: f.size(50);
    color: #fff;
    font-size: f.size(10);
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .l-footer {
    padding: f.size(60, 1920) f.size(20, 1920) f.size(20, 1920);
    &__logo {
      gap: f.size(20, 1920);
      transition: 0.3s;
      img {
        &:first-of-type {
          width: f.size(200, 1920);
          height: f.size(200, 1920);
        }
        &:last-of-type {
          width: f.size(200, 1920);
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    &__sns {
      margin-top: f.size(20, 1920);
      gap: f.size(20, 1920);
      img {
        &:first-of-type {
          width: f.size(50, 1920);
          height: f.size(50, 1920);
        }
        &:last-of-type {
          width: f.size(50, 1920);
          height: f.size(50, 1920);
        }
      }
    }
    &__copy {
      margin-top: f.size(50, 1920);
      font-size: f.size(14, 1920);
    }
  }
}
