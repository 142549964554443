@use './function' as f;
@use './mixin' as m;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif:wght@400;700&display=swap');

body {
  background-color: #fafafa;
  color: #222;
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden;
}

html {
  &.open {
    .l-nav {
      transform: translate(0, 0);
    }
    .l-header__burger {
      &--item {
        &:nth-of-type(1) {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
        &:nth-of-type(2) {
          transform: rotate3d(1, 0, 0, 90deg);
        }
        &:nth-of-type(3) {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
}

.ff-serif {
  font-family: 'Noto Serif', serif;
}

.is-pc {
  display: none;
}

@include m.media(for-pc) {
  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }
  .pc-flex {
    display: flex;
    gap: f.size(40, 1920);
    &-col {
      display: flex;
      flex-direction: column;
    }
  }
  .jc-center {
    justify-content: center;
  }
  .align-c {
    align-items: center;
  }
}
