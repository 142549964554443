@use '../foundation/mixin' as m;
@use '../foundation/function' as f;

.style {
  &__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: f.size(30) f.size(15);
  }
  &__item {
    position: relative;
    height: f.size(230);
    div {
      height: f.size(230);
      background-color: #fff;
      border-radius: f.size(4);
      @include m.shadow;
      padding: f.size(8);
    }
    &--front,
    &--back {
      transition: all 0.6s;
      position: absolute;
      left: 0;
      top: 0;
      backface-visibility: hidden;
    }
    &--back {
      transform: rotateY(180deg);
    }
    &.active {
      .style__item {
        &--front {
          transform: rotateY(180deg);
        }
        &--back {
          transform: rotateY(0);
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  &__note {
    font-size: f.size(10);
    color: #606060;
  }
}

/* ---------------------------------------- */
/*  responsive                              */
/* ---------------------------------------- */
@include m.media(for-pc) {
  .style {
    &__wrap {
      width: f.size(900, 1440);
      margin: 0 auto;
      grid-template-columns: repeat(4, 1fr);
      gap: f.size(30, 1440) f.size(30, 1440);
    }
    &__item {
      height: f.size(250, 1440);
      div {
        height: f.size(250, 1440);
        border-radius: f.size(4, 1440);
        padding: f.size(8, 1440);
      }
      &--front,
      &--back {
        width: -webkit-fill-available;
      }
    }
    &__note {
      margin-top: f.size(4, 1440);
      font-size: f.size(10, 1440);
    }
  }
}
